/**
 * @generated SignedSource<<7562088ba56af5146672208cc58060b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EntityUpdateThemeInput = {
  clientMutationId?: string | null;
  entityThemeData: EntityUpdateTheme;
};
export type EntityUpdateTheme = {
  id: string;
  theme: string;
};
export type ThemeContextProviderUpdateEntityThemeMutation$variables = {
  input: EntityUpdateThemeInput;
};
export type ThemeContextProviderUpdateEntityThemeMutation$data = {
  readonly entityUpdateTheme: {
    readonly entity: {
      readonly __typename: string;
      readonly theme: string | null;
    } | null;
    readonly errors: ReadonlyArray<string>;
  };
};
export type ThemeContextProviderUpdateEntityThemeMutation = {
  response: ThemeContextProviderUpdateEntityThemeMutation$data;
  variables: ThemeContextProviderUpdateEntityThemeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "theme",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ThemeContextProviderUpdateEntityThemeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EntityUpdateThemePayload",
        "kind": "LinkedField",
        "name": "entityUpdateTheme",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "entity",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ThemeContextProviderUpdateEntityThemeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EntityUpdateThemePayload",
        "kind": "LinkedField",
        "name": "entityUpdateTheme",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "entity",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4699ded7b28d192e9dd5aada4c7e72bc",
    "id": null,
    "metadata": {},
    "name": "ThemeContextProviderUpdateEntityThemeMutation",
    "operationKind": "mutation",
    "text": "mutation ThemeContextProviderUpdateEntityThemeMutation(\n  $input: EntityUpdateThemeInput!\n) {\n  entityUpdateTheme(input: $input) {\n    errors\n    entity {\n      __typename\n      theme\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f451d336bba72db39844fead976cbdf6";

export default node;
